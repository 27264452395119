@font-face {
  font-family: Lato;
  src: url("./Lato-Regular.woff2") format("woff2"), url("./Lato-Regular.woff") format("woff");
  /* src: url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Regular.woff2")
      format("woff2"),
    url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Regular.woff") format("woff"); */
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url("./Lato-Medium.woff2") format("woff2"), url("./Lato-Medium.woff") format("woff");
  /* src: url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Medium.woff2")
      format("woff2"),
    url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Medium.woff") format("woff"); */
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url("./Lato-Semibold.woff2") format("woff2"), url("./Lato-Semibold.woff") format("woff");
  /* src: url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Semibold.woff2")
      format("woff2"),
    url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Semibold.woff") format("woff"); */
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url("./Lato-Bold.woff2") format("woff2"), url("./Lato-Bold.woff") format("woff");
  /* src: url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Bold.woff2")
      format("woff2"),
    url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Bold.woff") format("woff"); */
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url("./Lato-Black.woff2") format("woff2"), url("./Lato-Black.woff") format("woff");
  /* src: url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Black.woff2")
      format("woff2"),
    url("https://smallpdevstorageacc.blob.core.windows.net/smallpdevcontainer/Lato-Black.woff") format("woff"); */
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
